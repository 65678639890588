<template>
  <div id="costs-page">
    <CostCard />
    <div v-if="loading" class="d-flex justify-center mt-10">
      <Spinner />
    </div>
    <!-- <v-skeleton-loader
      v-if="loading"
      type="card"
      class="mx-auto my-12"
      max-width="600"
      :height="700"
    ></v-skeleton-loader> -->
    <v-card
      v-else
      color="element"
      class="mx-auto my-12"
      max-width="640"
    >
      <v-card-title class="mobile-title">{{ $t('subscription.costs.title') }}</v-card-title>
      <v-card-text class="mobile-text">
        <p class="defaultText--text">
          {{ $t('subscription.costs.desc') }}
        </p>
        <v-card class="px-2 py-2" color="backgroundDark">
          <v-card color="element" class="px-2 py-2">
            <v-row>
              <v-col>
                {{ $t('subscription.costs.LZ') }}
              </v-col>
              <v-col align="right">
                {{ subscriptionFees.LZ + ' ' + $t('subscription.costs.years') }}
              </v-col>
            </v-row>
          </v-card>
        </v-card>

        <v-card
          class="px-2 py-2 mt-5"
          :color="index === 'KG' ? 'backgroundLight' : 'backgroundDark'"
          v-for="(main, index) in subscriptionFees.main"
          :key="index"
        >
          <v-row class="px-2">
            <v-col
              cols=6
              class="section-title"
            >
              {{ $t(`subscription.costs.main.${index}`) }}
            </v-col>
            <v-col
              cols=6
              class="section-title"
              align="right"
            >
              {{ $n(main, 'floatCurrency') }}
            </v-col>
          </v-row>

          <div v-for="(component, comIndex) in subscriptionFees.components[index]" :key="comIndex">
            <v-card
              color="element"
              class="px-2 py-2 mt-3"
              v-if="comIndex !== 'KGA'"
            >
              <v-row align="center">
                <v-col cols="7">
                  {{ $t(`subscription.costs.components.${index}.${comIndex}`, {
                    years: subscriptionFees.LZ,
                  }) }}
                </v-col>
                <v-col cols="2" align="right" v-if="comIndex !== 'KAA'">
                  {{
                    $n(
                      component
                      / (comIndex === 'KAB' ? subscriptionFees.components.KA.KAA : subscriptionFees.main.KA)
                      , 'percent'
                      )
                  }}
                </v-col>
                <v-col :cols="comIndex !== 'KAA' ? 3 : 5" align="right">
                  {{ $n(component, 'floatCurrency') }}
                </v-col>
              </v-row>
            </v-card>

            <v-row align="center" class="px-2" v-else>
              <v-col cols="7" class="d-flex">
                <span>{{ $t(`subscription.costs.components.${index}.${comIndex}`) }}</span>
              </v-col>
              <v-col cols="2" align="right">
                {{ $n(component / subscriptionFees.main.KA, 'percent') }}
              </v-col>
              <v-col cols="3" align="right">
                {{ $n(component, 'floatCurrency') }}
              </v-col>
            </v-row>

            <div class="py-3" v-if="subscriptionFees.subComponents[comIndex]">
              <v-row
                align="center"
                class="px-2"
                v-for="(subcomponent, subIndex) in subscriptionFees.subComponents[comIndex]" :key="subIndex"
              >
                <v-col cols="7" class="d-flex">
                  <div class="border-point element" />
                  <span>{{ $t(`subscription.costs.subComponents.${comIndex}.${subIndex}`) }}</span>
                </v-col>
                <v-col cols="2" align="right">
                  {{ $n(subcomponent / subscriptionFees.main.KA, 'percent') }}
                </v-col>
                <v-col cols="3" align="right">
                  {{ $n(subcomponent, 'floatCurrency') }}
                </v-col>
              </v-row>
            </div>
          </div>

        </v-card>

        <div
          class="pt-5"
        >
          <CardNav
            id="costs-card-nav"
            :nextText="$t('subscription.navigation.next')"
            :prevText="$t('subscription.navigation.back')"
            @next="nextStep(1);"
            @previous="back"
            :nextLoading="loading"
            :prevDisabled="loading"
          />
        </div>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import CardNav from '@/components/Shared/CardNav.vue';
import Spinner from '@/components/Shared/Spinner.vue';

export default {
  name: 'Costs',
  components: {
    CostCard: lazyLoad('components/Subscription/CostCard'),
    CardNav,
    Spinner,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    await this.getSubscriptionFees();
    this.loading = false;
  },
  computed: {
    ...mapGetters('subscription', [
      'subscriptionFees',
      'passedAdequacyCheck',
      'investorSubscriptionInformation',
    ]),
  },
  methods: {
    ...mapActions('subscription', [
      'previousStep',
      'nextStep',
      'getSubscriptionFees',
      'getSubscriptionDocuments',
    ]),
    back() {
      if (this.investorSubscriptionInformation.investorClassification !== 'Private' || this.passedAdequacyCheck) {
        this.previousStep(2);
      } else {
        this.previousStep(1);
      }
    },
  },
};
</script>

<style lang="scss">
#costs-page {
  .section-title {
    font-size: 1.1em;
    font-weight: 500;
  }
  .border-point {
    min-width: 5px;
    border-radius: 5px;
    margin-right: 10px;
  }
  // .v-skeleton-loader__image.v-skeleton-loader__bone {
  //   height: 400px;
  // }
}

@media (max-width: 600px) {
  .mobile-title {
    font-size: 16px !important;
  }
  .mobile-text {
    font-size: 10px !important;
  }
  #costs-page {
    .col-2 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
</style>
